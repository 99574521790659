import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body{
    background-color: #000000;
    @font-face {
        font-family: "Roboto";
        src: local("Roboto"),
         url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
    }
    @font-face {
        font-family: "Roboto-Bold";
        src: local("Roboto"),
         url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
    }
    @font-face {
        font-family: "Roboto-Thin";
        src: local("Roboto-Thin"),
         url("../assets/fonts/Roboto-Thin.ttf") format("truetype");
    }
  }
`

export default GlobalStyle
