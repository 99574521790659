import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// import logo from '../../assets/images/logo.png'
import AnimatedLogo from '../animatedLogo'

const Header = ({ pathLength }) => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <AnimatedLogo
          fill='white'
          width='100px'
          pathLength={pathLength}
        ></AnimatedLogo>
      </LogoContainer>
      <MenuContainer>
        {/* <MenuElement>
          <MenuText>Notre Travail</MenuText>
        </MenuElement>
        <MenuElement>
          <MenuText>Nos Services</MenuText>
        </MenuElement>
        <MenuElement>
          <MenuText>Entreprise</MenuText>
        </MenuElement>
        <MenuElement>
          <MenuText>Contact</MenuText>
        </MenuElement> */}
      </MenuContainer>
    </HeaderContainer>
  )
}

const MenuText = styled.span`
  font-family: Roboto;
  color: white;
`
const MenuElement = styled.div`
  cursor: pointer;
  margin: 0px 12px;
`

const LogoContainer = styled.div`
  display: flex;
`

const MenuContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
`

export default Header
