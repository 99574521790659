import { useEffect, useState } from 'react'
import GlobalStyle from './config/globalStyle'
import Header from './components/header'
import {
  useViewportScroll,
  useSpring,
  useTransform,
  motion
} from 'framer-motion'
import styled from 'styled-components'
import { FaSnapchatGhost } from 'react-icons/fa'
import {
  AiOutlineMail,
  AiOutlineTwitter,
  AiFillInstagram
} from 'react-icons/ai'
import AnimatedLogo from './components/animatedLogo'
import musliminApp from './assets/images/musliminApp.png'
import InView, { useInView } from 'react-intersection-observer'

import { devices } from './config/devices'

const App = () => {
  const { scrollYProgress } = useViewportScroll()
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false
  })

  const [ref2, inView2, entry2] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false
  })

  console.log('🚀 ~ file: App.js ~ line 19 ~ App ~ inView', inView)
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1])
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 })

  return (
    <SiteContainer>
      <GlobalStyle></GlobalStyle>
      <HeaderContainer>
        <Header pathLength={pathLength}></Header>
      </HeaderContainer>
      <Content>
        <TitleContainer>
          <Title>
            The KF Company est une agence de création de contenus numériques
            centrée sur la création d'application.
          </Title>
        </TitleContainer>
      </Content>
      <AnimatedPresentation
        variants={presentationVariants}
        initial='hidden'
        animate={inView ? 'visible' : 'hidden'}
        ref={ref}
      >
        <AppImage src={musliminApp}></AppImage>
        <PresentationContainer>
          <Description>
            <Title>Muslimin</Title>
            <SubTitle>
              Un utilitaire indispensable a tout les musulmans
            </SubTitle>
          </Description>
        </PresentationContainer>
      </AnimatedPresentation>
      <ButtonRow>
        <ButtonContainer
          onClick={() => window.open('http://onelink.to/muslimin', '_blank')}
        >
          <ButtonText>Télécharger</ButtonText>
        </ButtonContainer>
      </ButtonRow>
      <AnimatedPresentation
        variants={presentationVariants}
        initial='hidden'
        animate={inView2 ? 'visible' : 'hidden'}
        ref={ref2}
      >
        <ContactContainer>
          <Description>
            <Title>Des Questions ? Besoin d'un devis ?</Title>
            <SubTitle>
              Contactez-nous par mail ou sur les réseaux sociaux
            </SubTitle>
          </Description>
          <Contact>
            <ContactRow>
              <ContactButton
                onClick={() =>
                  window.open('mailto:contact@thekfcompany.com', '_blank')
                }
              >
                <AiOutlineMail></AiOutlineMail>
              </ContactButton>
              <ContactButton
                onClick={() =>
                  window.open(
                    'https://www.instagram.com/musliminapp/',
                    '_blank'
                  )
                }
              >
                <AiFillInstagram></AiFillInstagram>
              </ContactButton>
            </ContactRow>
            <ContactRow>
              <ContactButton
                onClick={() =>
                  window.open(
                    'https://www.snapchat.com/add/musliminapp',
                    '_blank'
                  )
                }
              >
                <FaSnapchatGhost></FaSnapchatGhost>
              </ContactButton>

              <ContactButton
                onClick={() =>
                  window.open('https://twitter.com/musliminapp', '_blank')
                }
              >
                <AiOutlineTwitter></AiOutlineTwitter>
              </ContactButton>
            </ContactRow>
          </Contact>
        </ContactContainer>
      </AnimatedPresentation>

      <Background>
        <AnimatedLogo opacity={0.2} pathLength={pathLength}></AnimatedLogo>
      </Background>
    </SiteContainer>
  )
}

const ButtonRow = styled.div`
  justify-content: flex-start;
  padding: 24px;
  display: flex;
`

const Contact = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
`

const ContactRow = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
const ContactButton = styled.div`
  background-color: ${props => (props.color ? props.color : 'white')};
  padding: 24px;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  margin: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  background-color: ${props => (props.color ? props.color : 'white')};
  padding: 24px;
  border-radius: 50px;
  cursor: pointer;
`
const ButtonText = styled.span`
  font-family: Roboto;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  padding: 12px 24px;
`

const presentationVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
}

const PresentationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem;
`

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem;
  flex-wrap: wrap;
`

const AppImage = styled.img`
  display: flex;
  width: 90%;
  height: 500px;
  @media ${devices.laptop} {
    width: 400px;
  }
`

const Title = styled.span`
  font-family: Roboto;
  color: white;
  font-size: calc((4 - 1) * 1.2vw + 1rem);
  line-height: 1.232;
`

const SubTitle = styled.span`
  font-family: Roboto-Thin;
  color: white;
  font-size: calc((4 - 1) * 1.2vw + 1rem);
  line-height: 1.232;
`
const Content = styled.div``
const AppPresentation = styled.div`
  padding-top: 13.2vmax;
  display: flex;
  /* flex-wrap: wrap-reverse; */
  width: 100%;
  justify-content: center;
  flex-wrap: wrap-reverse;
  @media ${devices.mobileL} {
    flex-wrap: nowrap;
  }
`
const AnimatedPresentation = motion(AppPresentation)

const TitleContainer = styled.div`
  max-width: 800px;
  margin: 2rem 1rem;
  padding-top: 10vmax;
  @media ${devices.laptop} {
    margin: 1rem;
    padding-top: 5vmax;
  }
`

const SiteContainer = styled.div`
  padding-bottom: 128px;
`

const Background = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  top: 0;
  z-index: -1;
`
// const Header = styled.div``

const AbsoluteHeader = styled.div`
  position: fixed;
`

export default App
